$(document).foundation();
$(document).ready(function() {
    initHeader();
    initSliders();
    initScrollbars();
    initLocations();
    initAnimateInView();
    initReveals();
    initRedirects();
    initOffers();
    initForms();
    initContentNavigation();
    initAccordions();
    initVideo();
    initNewsletterSelect();
    initRollerCounterInView();
});

function initVideo() {
    if ($('.home-video').length > 0) {
        
        coverViewport($('.home-video__inner iframe'), true);
		$(window).on('resize', function () {
			coverViewport($('.home-video__inner iframe'), true);
		});

        $('.home-video__toggle-sound').on('click', function(e) {
            e.preventDefault();

            var $muteButton = $(this);
            var homeVideoPlayer = new Vimeo.Player($('.home-video iframe'));

            homeVideoPlayer.getVolume().then(function(volume) {
                if (volume == 0) {
                    homeVideoPlayer.setVolume(1);
                    $muteButton.removeClass('muted');
                    $muteButton.attr('title', $muteButton.data('mute-text'));
                } else {
                    homeVideoPlayer.setVolume(0);
                    $muteButton.addClass('muted');
                    $muteButton.attr('title', $muteButton.data('unmute-text'));
                }
            });
        });
    }




    /**
 * Scales any element to simulate the CSS background-size: cover property.
 * @param {DOMelement} element The element to scale
 * @param {bool} [scaleToParent] Scale the element to fill the parent instead
 *                               of the whole browser window.
 */
function coverViewport(element, scaleToParent) {
    console.log(element)
	if (typeof scaleToParent === 'undefined') { scaleToParent = false; }

	var viewportWidth, viewportHeight;
	if (scaleToParent) {
		viewportWidth = $(element).parent().innerWidth();
		viewportHeight = $(element).parent().innerHeight();
	} else {
		viewportWidth = $(window).innerWidth();
		viewportHeight = $(window).innerHeight();
	}

	var $element = $(element);

	var elementWidth = $element.attr('width');
	var elementHeight = $element.attr('height');

	// Aspect ratio = height / width
	var viewportRatio = viewportHeight / viewportWidth;
	var videoRatio = elementHeight / elementWidth;

	//console.log('Viewport: ', viewportRatio, ' Video: ', videoRatio);


	var newWidth = (elementWidth / elementHeight) * viewportHeight
	var newHeight = (elementHeight / elementWidth) * viewportWidth;
	var newTop = 0;

	if (viewportRatio > videoRatio) {
		// set video height to the viewport, and extend the video beyond the viewport width, i.e. portrait viewport
		//console.log('crop left and right');
		$element.css('height', viewportHeight);
		$element.css('width', newWidth);
		$element.css('left', '-' + (newWidth - viewportWidth) / 2 + 'px');
		$element.css('top', '0');
	} else if (viewportRatio < videoRatio) {
		// set video width to the viewport, and extend the video beyond the viewport height, i.e. landscape viewport
		//console.log('crop top and bottom');
		$element.css('width', viewportWidth);
		$element.css('height', newHeight);
		$element.css('left', '0');
		$element.css('top', '-' + (newHeight - viewportHeight) / 2 + 'px');
	} else {
		// set the video width AND height to the viewport size, i.e. viewport is the same ratio as the video
		//console.log('no crop');
		$element.css('height', viewportHeight);
		$element.css('width', viewportWidth);
		$element.css('left', '0');
		$element.css('top', '0');
	}
}


}






function initHeader() {
    // toggle header solid state when the dropdown or off canvas menu is shown
    $('.header__dropdown')
        .on('show.zf.dropdown', function() {
            $(this)
                .closest('.header')
                .addClass('header--active');
        })
        .on('hide.zf.dropdown', function() {
            $(this)
                .closest('.header')
                .removeClass('header--active');
        });

    // Commented out to fix newsletter animation on first load

    // $('.off-canvas')
    //     .on('opened.zf.offcanvas', function() {
    //         $('.header').addClass('header--active');
    //     })
    //     .on('closed.zf.offcanvas', function() {
    //         $('.header').removeClass('header--active');
    //     });
}

function initSliders() {
    if ($('.home-video').length > 0) {
        return
    }


    if ($('.slider--full').length === 0) {
        $('#mainContent').addClass('no-full-slider') 
    }

    if ($('.slider').length === 0) {
        $('#mainContent').addClass('no-slider') 
    }

    $('[data-slick]').slick({
        speed: 1500,
        autoplaySpeed: 3000,
        prevArrow: '<button type="button" class="slick-prev"></button>',
        nextArrow: '<button type="button" class="slick-next"></button>'
    });


    $('.numbers-slider__inner').slick({
        speed: 300,
        slidesToShow: 3,
        slidesToScroll: 3,
        responsive: [
          {
            breakpoint: 1024,
            settings: {
              slidesToShow: 2,
              slidesToScroll: 2,
            }
          },
          {
            breakpoint: 600,
            settings: {
              slidesToShow: 1,
              slidesToScroll: 1
            }
          },
        ]
      })
}

function initMaps() {
    // this gets called by the Google Maps callback when it has loaded
    var mapStyles = [
        {
            featureType: 'all',
            elementType: 'labels',
            stylers: [
                {
                    visibility: 'off'
                },
                {
                    color: '#f49f53'
                }
            ]
        },
        {
            featureType: 'administrative.locality',
            elementType: 'all',
            stylers: [
                {
                    visibility: 'on'
                },
                {
                    weight: '0.01'
                },
                {
                    color: '#505254'
                }
            ]
        },
        {
            featureType: 'administrative.neighborhood',
            elementType: 'all',
            stylers: [
                {
                    color: '#637381'
                },
                {
                    visibility: 'on'
                },
                {
                    weight: '0.01'
                }
            ]
        },
        {
            featureType: 'landscape',
            elementType: 'all',
            stylers: [
                {
                    color: '#999381'
                },
                {
                    lightness: -7
                },
                {
                    gamma: '10.00'
                }
            ]
        },
        {
            featureType: 'poi',
            elementType: 'all',
            stylers: [
                {
                    color: '#ffffff'
                }
            ]
        },
        {
            featureType: 'poi.business',
            elementType: 'all',
            stylers: [
                {
                    lightness: 38
                }
            ]
        },
        {
            featureType: 'poi.government',
            elementType: 'all',
            stylers: [
                {
                    lightness: 46
                }
            ]
        },
        {
            featureType: 'poi.medical',
            elementType: 'geometry.fill',
            stylers: [
                {
                    lightness: 38
                }
            ]
        },
        {
            featureType: 'poi.park',
            elementType: 'all',
            stylers: [
                {
                    lightness: 39
                }
            ]
        },
        {
            featureType: 'poi.school',
            elementType: 'all',
            stylers: [
                {
                    lightness: 35
                }
            ]
        },
        {
            featureType: 'poi.sports_complex',
            elementType: 'all',
            stylers: [
                {
                    lightness: 32
                }
            ]
        },
        {
            featureType: 'road',
            elementType: 'all',
            stylers: [
                {
                    lightness: 43
                },
                {
                    visibility: 'off'
                }
            ]
        },
        {
            featureType: 'road.highway',
            elementType: 'all',
            stylers: [
                {
                    color: '#c1beb1'
                },
                {
                    visibility: 'off'
                }
            ]
        },
        {
            featureType: 'road.highway',
            elementType: 'geometry.fill',
            stylers: [
                {
                    weight: '0.80'
                },
                {
                    visibility: 'on'
                }
            ]
        },
        {
            featureType: 'road.arterial',
            elementType: 'all',
            stylers: [
                {
                    visibility: 'on'
                },
                {
                    color: '#dadce0'
                }
            ]
        },
        {
            featureType: 'road.local',
            elementType: 'geometry.fill',
            stylers: [
                {
                    weight: 1.3
                },
                {
                    lightness: 16
                }
            ]
        },
        {
            featureType: 'road.local',
            elementType: 'geometry.stroke',
            stylers: [
                {
                    lightness: -10
                }
            ]
        },
        {
            featureType: 'transit',
            elementType: 'all',
            stylers: [
                {
                    lightness: 38
                }
            ]
        },
        {
            featureType: 'transit.line',
            elementType: 'all',
            stylers: [
                {
                    color: '#813033'
                },
                {
                    lightness: 22
                },
                {
                    visibility: 'off'
                }
            ]
        },
        {
            featureType: 'transit.station',
            elementType: 'all',
            stylers: [
                {
                    visibility: 'off'
                }
            ]
        },
        {
            featureType: 'water',
            elementType: 'all',
            stylers: [
                {
                    color: '#ffffff'
                },
                {
                    saturation: -69
                },
                {
                    gamma: 0.99
                },
                {
                    lightness: 43
                }
            ]
        }
    ];

    var mapMap = $('.map__map');

    if (mapMap.length > 0) {
        var latLng = mapMap.data('lat-lng').split(',');
        var zoomLevel = mapMap.data('zoom');

        if (latLng.length === 2) {
            var map = new google.maps.Map(mapMap.get(0), {
                center: new google.maps.LatLng(latLng[0], latLng[1]),
                zoom: zoomLevel,
                zoomControlOptions: {
                    position: google.maps.ControlPosition.LEFT_BOTTOM
                },
                zoomOnClick: true,
                streetViewControl: false,
                scrollwheel: false,
                fullscreenControl: false,
                mapTypeControl: false,
                mapTypeId: google.maps.MapTypeId.ROADMAP,
                styles: mapStyles
            });

            map.addListener('click', function() {
                // hide all items when tapping the map
                $('.map__item').hide();
                $('img[src^="/assets/img/map-marker.png#"]').removeClass('active');

                // zoom back to center
                map.panTo(new google.maps.LatLng(latLng[0], latLng[1]));
                map.setZoom(zoomLevel);
            });

            var markers = [];
            var markersBounds = new google.maps.LatLngBounds();

            $('.map__item').each(function() {
                var item = $(this);
                var itemId = item.data('id');
                var itemLatLng = item.data('lat-lng').split(',');

                if (itemLatLng.length === 2) {
                    var marker = new google.maps.Marker({
                        position: new google.maps.LatLng(itemLatLng[0], itemLatLng[1]),
                        map: map,
                        optimized: false,
                        icon: {
                            url: '/assets/img/map-marker.png#' + itemId,
                            size: new google.maps.Size(60, 60),
                            origin: new google.maps.Point(0, 0),
                            anchor: new google.maps.Point(30, 30)
                        },
                        id: itemId
                    });

                    marker.addListener('click', function() {
                        // set CSS class of active marker
                        $('img[src^="/assets/img/map-marker.png#"]').removeClass('active');
                        $('img[src="/assets/img/map-marker.png#' + marker.id + '"]').addClass('active');

                        // pan and zoom to marker
                        map.panTo(marker.position);
                        map.setZoom(10);

                        // close other items and show clicked item
                        $('.map__item').hide();
                        $('.map__item[data-id="' + marker.id + '"]').show();
                    });

                    markers.push(marker);
                    markersBounds.extend(marker.position);
                }
            });

            // make sure map fits the marker bounds
            map.fitBounds(markersBounds);

            // set marker clusterer
            var mapClusterer = new MarkerClusterer(map, markers, {
                gridSize: 17,
                ignoreHidden: true,
                zoomOnClick: false,
                styles: [
                    {
                        url: '/assets/img/map-group-marker.png',
                        width: 35,
                        height: 35,
                        anchorText: [0, 0],
                        anchorIcon: [15, 15],
                        textColor: '#fff'
                    }
                ]
            });

            google.maps.event.addListener(mapClusterer, 'clusterclick', function(c) {
                map.fitBounds(c.getBounds());
            });

            // show or hide markers based on the grid item visibility (set in the filter function)
            mapMap.on('markers.refresh', function(e) {
                // close any open items
                $('img[src^="/assets/img/map-marker.png#"]').removeClass('active');
                $('.map__item').hide();

                // show or hide markers
                markers.forEach(function(m) {
                    var isHidden = $('.map__item[data-id="' + m.id + '"][data-hidden="true"]').length > 0;

                    m.setVisible(!isHidden);
                });

                // refresh marker clusters
                mapClusterer.setMap(null);
                mapClusterer.setMap(map);
            });

            // open map marker when triggered from elsewhere (e.g. locations list)
            mapMap.on('markers.open', function(e, args) {
                markers.forEach(function(m) {
                    if (m.id === args.id) {
                        google.maps.event.trigger(m, 'click');
                    }
                });
            });
        }
    }

    var locationMap = $('.location__map');

    if (locationMap.length > 0) {
        var latLng = locationMap.data('lat-lng').split(',');
        var zoomLevel = locationMap.data('zoom');

        if (latLng.length === 2) {
            var map = new google.maps.Map(locationMap.get(0), {
                center: new google.maps.LatLng(latLng[0], latLng[1]),
                zoom: zoomLevel,
                zoomControl: true,
                streetViewControl: false,
                scrollwheel: false,
                fullscreenControl: false,
                mapTypeControl: false,
                mapTypeId: google.maps.MapTypeId.ROADMAP,
                styles: mapStyles
            });

            var marker = new google.maps.Marker({
                position: new google.maps.LatLng(latLng[0], latLng[1]),
                map: map,
                optimized: false,
                icon: {
                    url: '/assets/img/map-marker.png',
                    size: new google.maps.Size(60, 60),
                    origin: new google.maps.Point(0, 0),
                    anchor: new google.maps.Point(15, 15)
                }
            });

            // setup directions form
            var old_v = '',
                ds = new google.maps.DirectionsService(),
                dr = new google.maps.DirectionsRenderer();
            dr.setMap(map);
            dr.setPanel($('#map-directions-data').get(0));

            $('#frmPostcode').bind('submit', function() {
                var v = $.trim($('#tbPostcode').val());
                if (v != '' && v != old_v) {
                    old_v = v;

                    var r = {
                        origin: v,
                        destination: latLng.join(','),
                        travelMode: google.maps.TravelMode.DRIVING,
                        unitSystem: google.maps.UnitSystem.IMPERIAL
                    };

                    ds.route(r, function(result, status) {
                        if (status == google.maps.DirectionsStatus.OK) {
                            marker.setMap(null);
                            dr.setDirections(result);

                            setTimeout(function() {
                                var x = document.getElementById('map-directions-data').innerHTML;
                                var str = x;
                                str = str.replace(/&/g, 'amp;');
                                str = str.replace(/>/g, 'gt;');
                                str = str.replace(/</g, 'lt;');
                                str = str.replace(/"/g, 'quot;');
                                str = str.replace(/'/g, '#039;');
                            }, 2000);
                        } else {
                            alert('Location ' + old_v + ' not found.');
                        }
                    });
                }
            });
        }
    }
}

function initScrollbars() {
    if (Foundation.MediaQuery.is('small only')) {
        // don't use scrollbars on mobile
        return;
    }

    // initialise the styled scrollbars
    $('[data-nice-scroll]').niceScroll({ autohidemode: false, horizrailenabled: false });

    // resize scroll bar when element gets resized
    $('[data-nice-scroll]').on('down.zf.accordion up.zf.accordion resizeme.zf.trigger', function(e) {
        $(this)
            .getNiceScroll()
            .resize();
    });

    // recreate when window gets resized
    $(window).on('resize', function() {
        $('[data-nice-scroll]')
            .getNiceScroll()
            .remove();

        $('[data-nice-scroll]').niceScroll({ autohidemode: false, horizrailenabled: false });
    });
}

function initLocations() {
    // view toggles
    $('.locations__toggles .button, .locations__heading button').on('click', function() {
        switch (this.value) {
            case 'sort':
                $('.locations__sort').toggleClass('locations__sort--active');
                break;
            case 'filter':
                $('.locations__filters').toggleClass('locations__filters--active');
                break;
            case 'map':
                $('.locations')
                    .removeClass('locations--list-view')
                    .addClass('locations--map-view');
                break;
            case 'list':
                $('.locations')
                    .removeClass('locations--map-view')
                    .addClass('locations--list-view');
                break;
        }
    });

    // list item click handlers
    $('.locations__result').on('click', function() {
        // toggle to map view (for mobile)
        $('.locations__toggles .button[value="map"]').trigger('click');

        // open map marker
        $('.locations__map .map__map').trigger('markers.open', { id: $(this).data('id') });
    });

    // filter & sort logic
    $('.locations__filters input[type="checkbox"], .locations__sort input[type="radio"]').on('change', function() {
        // close sort panel
        $('.locations__sort').removeClass('locations__sort--active');

        // toggle to list view (for mobile)
        $('.locations__toggles .button[value="list"]').trigger('click');

        // get filter values
        var filterRegions = $('.locations__filters input[type="checkbox"][name="filter-region"]:checked')
            .map(function() {
                return parseInt(this.value);
            })
            .get();
        var filterPropertyTypes = $('.locations__filters input[type="checkbox"][name="filter-property-type"]:checked')
            .map(function() {
                return parseInt(this.value);
            })
            .get();
        var filterExperiences = $('.locations__filters input[type="checkbox"][name="filter-experiences"]:checked')
            .map(function() {
                return parseInt(this.value);
            })
            .get();
        var filterAudience = $('.locations__filters input[type="checkbox"][name="filter-audience"]:checked')
            .map(function() {
                return parseInt(this.value);
            })
            .get();

        var sortOrder = $('.locations__sort input[type="radio"][name="sort"]:checked').val();

        // apply filters to all listed locations
        var locations = $('.locations__result');

        locations.each(function(i, el) {
            var location = $(el);

            var valid = true;

            var locationId = location.data('id');
            var regions = location.data('regions');
            var propertyTypes = location.data('property-types');
            var experiences = location.data('experiences');
            var audience = location.data('audience');

            if (filterRegions && filterRegions.length > 0 && regions) {
                var validRegion = false;

                filterRegions.forEach(function(ft) {
                    if (regions.indexOf(ft) >= 0) {
                        validRegion = true;
                    }
                });

                if (!validRegion) {
                    // none of the regions of this location match the filter
                    valid = false;
                }
            }

            if (filterPropertyTypes && filterPropertyTypes.length > 0 && propertyTypes) {
                var validPropertyType = false;

                filterPropertyTypes.forEach(function(ft) {
                    if (propertyTypes.indexOf(ft) >= 0) {
                        validPropertyType = true;
                    }
                });

                if (!validPropertyType) {
                    // none of the property types of this location match the filter
                    valid = false;
                }
            }

            if (filterExperiences && filterExperiences.length > 0 && experiences) {
                var validExperience = false;

                filterExperiences.forEach(function(ft) {
                    if (experiences.indexOf(ft) >= 0) {
                        validExperience = true;
                    }
                });

                if (!validExperience) {
                    // none of the experiences of this location match the filter
                    valid = false;
                }
            }

            if (filterAudience && filterAudience.length > 0 && audience) {
                var validAudience = false;

                filterAudience.forEach(function(ft) {
                    if (audience.indexOf(ft) >= 0) {
                        validAudience = true;
                    }
                });

                if (!validAudience) {
                    // none of the audience of this location match the filter
                    valid = false;
                }
            }

            // toggle visibility class based on filter checks
            location.toggleClass('locations__result--hidden', !valid);

            // toggle map marker
            $('.map__item[data-id="' + locationId + '"]').attr('data-hidden', !valid);
        });

        // refresh the map
        $('.locations__map .map__map').trigger('markers.refresh');

        // order the list of valid items
        var visibleLocations = locations.filter(':not(.locations__result--hidden)').get();

        var sortedLocations = visibleLocations.sort(function(a, b) {
            var locationAName = $(a).data('name');
            var locationBName = $(b).data('name');

            switch (sortOrder) {
                case 'asc':
                    return locationAName.toUpperCase().localeCompare(locationBName.toUpperCase());
                case 'desc':
                    return locationBName.toUpperCase().localeCompare(locationAName.toUpperCase());
                case 'inspire':
                    return 0.5 - Math.random(); // random(ish) order
            }
        });

        // set flexbox order for sorted list
        sortedLocations.forEach(function(location, i) {
            $(location).css('order', i + 1);
        });
    });
}

function initAnimateInView() {
    $(window).on('DOMContentLoaded load resize scroll', animateElementsInView);
    animateElementsInView();
}

function animateElementsInView() {
    $('[data-animate-in-view]').each(function() {
        //console.log($(this));
        if (isElementInViewport($(this))) {
            $(this).addClass($(this).data('animate-in-view'));
        }
    });
}

// https://stackoverflow.com/questions/123999/how-to-tell-if-a-dom-element-is-visible-in-the-current-viewport
function isElementInViewport(el) {
    //special bonus for those using jQuery
    if (typeof jQuery === 'function' && el instanceof jQuery) {
        el = el[0];
    }

    var rect = el.getBoundingClientRect();

    return rect.top <= $(window).scrollTop() + window.innerHeight;
}

function initReveals() {
    // show popup after specified period
    $('[data-reveal-after]').each(function() {
        var reveal = $(this);

        setTimeout(function() {
            reveal.foundation('open');
        }, reveal.data('reveal-after') * 1000);
    });

    $('a[href="#close"]').on('click', function () {
	    $('.reveal').foundation('close');
		return false;
    });
}

function initRedirects() {
    // show popup before redirecting user
    $('[data-redirect]').on('click', function(e) {
        var link = $(this);
        var url = link.attr('href');
        var title = link.data('redirect');

        // create popup
        var reveal = $(
            '<div class="reveal large reveal--redirect" data-reveal data-additional-overlay-classes="reveal__overlay--loading">' +
                '<div class="reveal__content">' +
                '<img src="/assets/img/logo-gold.png" alt="" />' +
                '<div class="reveal__heading">Just connecting you to</div>' +
                '<div class="reveal__title">' +
                title +
                '</div>' +
                '<div class="reveal__footer">thank you</div>' +
                '</div>' +
                '</div>'
        );
        reveal.appendTo($('body'));

        // open popup
        reveal.foundation();
        reveal.foundation('open');

        // wait for 3 seconds and redirect
        var redirectTimeout = setTimeout(function() {
            window.location.href = url;
        }, 3000);

        reveal.on('closed.zf.reveal', function() {
            // cancel the redirect when closing the popup
            clearTimeout(redirectTimeout);
        });

        e.preventDefault();
        return false;
    });
}

function initOffers() {
    $('.offers__load-more button').on('click', function() {
        alert('To do: load offers over ajax');
    });
}

function initForms() {
    $('.form__checkbox-dropdown h2').on('click', function() {
        if (
            !$(this)
                .parent()
                .hasClass('open')
        ) {
            // close others first before opening
            $('.form__checkbox-dropdown.open').removeClass('open');
        }

        $(this)
            .parent()
            .toggleClass('open');

        setTimeout(function() {
            $('[data-nice-scroll]')
                .getNiceScroll()
                .resize();
        }, 500);
    });
}

// https://stackoverflow.com/questions/24933430/img-src-svg-changing-the-fill-color
function inlineSVGs(inputValue) {
    var selectorString = '';
    if (typeof inputValue === 'object') {
        selectorString = inputValue.join(',');
    } else {
        selectorString = inputValue;
    }

    $(selectorString).each(function() {
        var $img = $(this);
        var imgID = $img.attr('id');
        var imgClass = $img.attr('class');
        var imgURL = $img.attr('src');

        if (imgURL.indexOf('.svg') > -1) {
            $.get(
                imgURL,
                function(data) {
                    // Get the SVG tag, ignore the rest
                    var $svg = $(data).find('svg');

                    // Add replaced image's ID to the new SVG
                    if (typeof imgID !== 'undefined') {
                        $svg = $svg.attr('id', imgID);
                    }
                    // Add replaced image's classes to the new SVG
                    if (typeof imgClass !== 'undefined') {
                        $svg = $svg.attr('class', imgClass + ' replaced-svg');
                    }

                    // Remove any invalid XML tags as per http://validator.w3.org
                    $svg = $svg.removeAttr('xmlns:a');

                    // Check if the viewport is set, if the viewport is not set the SVG wont't scale.
                    if (!$svg.attr('viewBox') && $svg.attr('height') && $svg.attr('width')) {
                        $svg.attr('viewBox', '0 0 ' + $svg.attr('height') + ' ' + $svg.attr('width'));
                    }

                    // Replace image with new SVG
                    $img.replaceWith($svg);
                },
                'xml'
            );
        }
    });
}

function initContentNavigation() {
    $('.content-navigation__open, .content-navigation__close').on('click', function() {
        $('.content-navigation').toggleClass('content-navigation--closed');
    });
}

function initAccordions() {
    $('.intro__more').accordiom({
        showFirstItem: false,
        buttonsBelowContent: true
    });
}

function initNewsletterSelect() {

    $('.newsletter-offcanvas__form-input select').selectric();
}


function initRollerCounter() {

    var counters = document.querySelectorAll('.numbers-slider__item-number');
    
    counters.forEach(function(counter) {
        var target = +counter.getAttribute('data-target');
        var count = 0;
        var inc = target / 100;

        var timer = setInterval(function() {
        count += inc;
        counter.innerText = Math.round(count);

        if (count >= target) {
            counter.innerText = target;
            clearInterval(timer);
        }
        }, 10);
    });
}

function initRollerCounterInView() {
    var numbersSlider = document.querySelector('.numbers-slider__inner');
  
    var observer = new IntersectionObserver(function(entries) {
      if (entries[0].isIntersecting) {
        initRollerCounter();
        observer.disconnect();
      }
    }, { threshold: 0.5 });
  
    observer.observe(numbersSlider);
  }

window.addEventListener('load', function() {

    // 'slider__logo' only exists on Homepage

    var isHomepage = document.querySelector('.slider__logo') !== null;
    
    if (isHomepage) {

        // Only for screens that don't have full-page popup instead of banner

        if (window.innerWidth >= 1040) {

            var button = document.querySelector('.newsletter-offcanvas__open button:first-child');
            
            if (button) {
                button.click();
                $(document.body).removeClass("is-off-canvas-open");
            }
        }
    }
});